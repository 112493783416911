.contactForm {
  width: 100%;
  padding: 20px;
  input, textarea {
    color: #f03f35;
  }
}


form {
    > div {
        width: 100%;
    }

}

.contactWrapper {
  display: flex;
  width: 100%;
  flex: 0 1;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}


.contactForm button {
  background-color: #f03f35;
  border: #f03f35;
  width: 100%;
  font-weight: bold;
}
