@import "~antd/dist/antd.css";

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
}

body {
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
}

.main {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #f03f35;
  margin-bottom: 30px;
}

h1 {
  line-height: 70px;
}


@media (min-width: 500px) {
  .main {
    width: 500px;
  }
}
