.heading {
  font-size: 60px;
  height: 150px;
}

.subHeading {
  margin-bottom: 10px;
}

.greeting {
  text-align: left;
}
